import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 719.000000 740.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,740.000000) scale(0.100000,-0.100000)">

<path d="M3495 5901 c-6 -4 -44 -26 -85 -49 -41 -22 -163 -87 -270 -144 -107
-58 -223 -120 -257 -138 l-63 -34 0 -908 0 -908 68 0 c287 1 530 182 617 460
14 43 19 106 22 287 l5 231 -28 15 c-136 71 -136 273 -1 343 l27 15 0 419 c0
428 -1 443 -35 411z m-95 -391 l0 -178 -96 -54 -95 -53 0 -500 c1 -275 -3
-505 -7 -512 -4 -6 -20 -15 -35 -18 l-27 -7 0 384 c0 212 -3 387 -6 391 -4 3
-17 -1 -30 -10 l-24 -15 0 -528 0 -527 -39 -11 c-21 -7 -50 -12 -65 -12 l-26
0 0 799 0 800 98 54 c225 126 337 184 345 180 4 -2 7 -84 7 -183z"/>
<path d="M3657 5523 c-4 -3 -7 -104 -7 -224 0 -162 3 -221 13 -228 6 -6 27
-23 45 -37 66 -53 90 -148 57 -226 -12 -30 -33 -52 -104 -110 -20 -17 -10
-431 14 -512 50 -177 168 -318 336 -402 65 -33 202 -64 282 -64 l67 0 0 718 0
717 -167 90 c-92 50 -203 110 -247 133 -45 24 -119 64 -166 89 -47 25 -89 49
-94 54 -12 11 -20 11 -29 2z"/>
<path d="M3552 4934 c-28 -19 -29 -67 -2 -94 24 -24 48 -25 78 -4 45 31 18
114 -38 114 -9 0 -26 -7 -38 -16z"/>
<path d="M3642 3268 c-7 -7 -12 -27 -12 -45 0 -45 37 -64 75 -39 31 21 35 70
6 86 -26 13 -54 13 -69 -2z"/>
<path d="M3888 3258 c-52 -22 -59 -33 -29 -43 17 -5 20 -21 26 -143 4 -75 5
-193 2 -262 -5 -110 -8 -127 -27 -142 -11 -9 -20 -19 -20 -22 0 -3 41 -6 90
-6 50 0 90 3 90 6 0 3 -10 14 -22 24 -23 18 -23 21 -27 314 -3 334 3 313 -83
274z"/>
<path d="M4138 3257 c-52 -24 -58 -32 -29 -40 28 -7 33 -60 29 -316 -3 -198
-5 -224 -20 -235 -10 -7 -18 -16 -18 -20 0 -3 38 -6 85 -6 47 0 85 2 85 5 0 3
-9 14 -20 25 -19 19 -20 33 -20 299 0 154 -3 286 -6 295 -8 21 -29 19 -86 -7z"/>
<path d="M2933 3234 c15 -8 32 -26 37 -40 13 -33 13 -465 0 -499 -5 -14 -22
-31 -37 -37 l-28 -12 35 -4 c19 -2 109 -2 200 0 155 3 168 5 215 30 63 33 107
77 137 137 18 37 23 63 23 136 0 111 -26 176 -91 234 -67 59 -126 71 -340 71
-166 -1 -177 -2 -151 -16z m318 -39 c95 -34 153 -129 152 -250 0 -83 -17 -134
-60 -184 -44 -52 -107 -81 -176 -81 -88 0 -87 -3 -87 264 0 127 4 236 8 242
18 27 98 31 163 9z"/>
<path d="M3623 3037 c-46 -22 -55 -37 -24 -37 28 0 31 -18 31 -176 0 -133 -2
-145 -20 -156 -36 -23 -22 -28 70 -28 53 0 90 4 90 10 0 6 -7 10 -15 10 -25 0
-35 69 -35 238 l0 162 -27 -1 c-16 0 -47 -10 -70 -22z"/>
<path d="M2752 2246 c-93 -30 -170 -110 -193 -200 -18 -73 -8 -201 21 -260 50
-99 147 -156 265 -156 119 0 212 55 270 161 28 50 30 62 30 154 0 92 -2 104
-30 154 -32 58 -86 109 -144 137 -47 23 -162 28 -219 10z m149 -37 c73 -25
113 -87 130 -205 21 -140 -14 -255 -94 -308 -36 -24 -50 -27 -100 -24 -71 5
-100 25 -141 98 -27 48 -31 66 -34 151 -5 118 13 191 60 241 50 54 111 69 179
47z"/>
<path d="M4805 2239 c-55 -26 -86 -75 -87 -135 -2 -87 28 -123 172 -204 125
-70 158 -116 126 -178 -43 -84 -201 -59 -250 39 -9 18 -16 40 -16 51 0 10 -4
18 -10 18 -10 0 -15 -64 -11 -144 2 -45 8 -57 21 -36 8 13 15 13 57 0 26 -8
77 -15 113 -17 119 -7 200 58 200 161 0 83 -22 112 -141 184 -166 101 -197
143 -149 205 54 68 182 26 218 -71 25 -68 27 -66 30 32 1 54 1 101 -2 103 -2
2 -13 -1 -24 -8 -16 -10 -30 -10 -72 4 -68 22 -125 21 -175 -4z"/>
<path d="M2040 2242 c0 -5 11 -13 25 -16 16 -4 27 -16 32 -34 10 -35 10 -459
0 -495 -5 -17 -19 -32 -40 -41 -30 -13 -22 -14 103 -15 129 0 133 0 100 15
-19 8 -38 20 -42 27 -4 7 -8 60 -8 120 l0 107 78 0 c103 0 157 26 190 90 43
85 18 168 -65 217 -46 27 -54 28 -210 31 -102 2 -163 0 -163 -6z m308 -77 c27
-30 32 -42 32 -86 0 -89 -65 -145 -145 -125 -25 6 -25 7 -25 125 0 133 0 133
70 126 24 -3 45 -15 68 -40z"/>
<path d="M3190 2240 c0 -5 8 -10 18 -10 11 0 26 -8 35 -18 15 -16 17 -50 17
-268 0 -137 -4 -254 -8 -261 -4 -7 -23 -19 -42 -27 -33 -15 -29 -15 100 -15
122 0 132 1 104 13 -48 21 -54 53 -54 285 0 219 8 276 40 286 30 10 83 -12
116 -46 l29 -30 3 -208 c4 -241 -3 -273 -60 -290 -26 -7 -8 -9 82 -10 l115 -1
-41 19 c-32 15 -42 26 -48 51 -10 51 -7 323 4 340 12 18 31 -1 185 -184 55
-66 109 -130 120 -142 11 -12 33 -39 48 -58 54 -70 56 -64 59 243 3 258 4 281
22 300 11 12 25 21 32 21 7 0 16 5 19 10 4 6 -30 10 -94 10 -97 0 -132 -11
-72 -23 16 -3 32 -14 36 -24 11 -26 19 -263 11 -317 -4 -25 -11 -46 -16 -46
-5 0 -37 33 -71 73 -34 39 -76 88 -93 108 -17 20 -66 80 -110 133 l-78 96
-204 0 c-129 0 -204 -4 -204 -10z"/>
<path d="M4140 2231 c-5 -11 -10 -42 -10 -70 0 -39 4 -51 15 -51 8 0 15 9 15
19 0 11 11 32 23 47 20 23 34 28 85 32 l62 4 0 -258 c0 -143 -4 -264 -8 -271
-4 -7 -23 -19 -42 -27 -33 -15 -29 -15 100 -16 135 0 135 0 100 18 -20 10 -37
27 -41 42 -10 37 -10 483 0 499 6 10 24 12 67 9 49 -4 62 -10 86 -37 15 -17
28 -38 28 -46 0 -8 5 -15 10 -15 7 0 10 25 8 68 l-3 67 -242 3 c-231 2 -243 1
-253 -17z"/>
<path d="M2135 1251 c-59 -28 -59 -29 -34 -39 24 -10 26 -15 32 -103 9 -117 9
-284 0 -372 -5 -60 -9 -70 -32 -82 -24 -13 -17 -14 79 -14 81 0 100 3 83 11
-30 14 -34 29 -40 134 -8 126 0 158 41 183 42 27 68 26 92 0 16 -18 20 -40 22
-144 4 -131 -3 -159 -41 -175 -15 -6 11 -9 81 -9 66 -1 101 3 97 9 -3 6 -12
10 -19 10 -21 0 -26 34 -26 173 0 138 -11 182 -51 211 -35 24 -100 20 -142 -9
-27 -17 -39 -21 -44 -12 -4 7 -10 66 -13 131 -3 66 -9 121 -15 123 -5 1 -37
-10 -70 -26z"/>
<path d="M4340 1273 c-37 -13 -100 -47 -100 -54 0 -4 10 -9 23 -11 18 -2 23
-11 28 -46 3 -24 3 -58 0 -75 l-6 -32 -70 0 c-61 0 -75 -4 -102 -25 -82 -65
-118 -213 -73 -300 24 -47 32 -57 70 -83 35 -24 120 -23 153 3 25 20 25 20 30
0 7 -26 35 -26 92 1 52 24 65 39 36 39 -11 0 -23 6 -28 13 -4 6 -10 138 -13
291 -3 174 -9 280 -15 282 -5 1 -17 0 -25 -3z m-88 -267 c28 -21 48 -92 48
-167 0 -82 -11 -116 -44 -130 -84 -39 -163 82 -136 208 19 86 81 128 132 89z"/>
<path d="M4957 1138 c-22 -29 -54 -64 -73 -78 -41 -30 -42 -38 -7 -42 37 -4
41 -24 42 -194 1 -127 3 -144 22 -168 16 -21 29 -26 64 -26 37 0 47 5 74 35
17 20 31 40 31 45 0 13 -27 13 -35 0 -10 -17 -44 -11 -55 9 -5 11 -10 80 -10
154 0 147 0 147 61 147 19 0 29 5 29 15 0 11 -11 15 -39 15 -48 0 -55 9 -61
83 l-5 58 -38 -53z"/>
<path d="M1820 1049 c-74 -31 -120 -110 -120 -206 0 -80 22 -136 69 -176 40
-34 68 -41 133 -35 44 5 57 11 89 45 39 42 66 110 50 126 -6 6 -16 -3 -25 -22
-31 -60 -119 -80 -171 -39 -99 78 -76 278 32 278 27 0 36 -5 44 -27 28 -69 32
-73 65 -73 53 0 60 51 15 102 -26 29 -35 32 -92 35 -35 1 -75 -2 -89 -8z"/>
<path d="M2655 1041 c-73 -45 -95 -90 -95 -197 0 -77 14 -124 46 -160 40 -43
67 -54 131 -54 55 0 66 3 97 30 37 33 71 105 64 136 -3 16 -7 13 -21 -13 -26
-49 -59 -67 -113 -60 -68 7 -124 69 -124 137 l0 30 130 0 130 0 0 31 c0 45
-28 96 -65 119 -43 27 -138 28 -180 1z m121 -46 c14 -14 24 -33 22 -43 -4 -22
-64 -37 -113 -28 -35 7 -37 9 -31 38 12 60 77 78 122 33z"/>
<path d="M3032 1043 c-85 -42 -74 -160 12 -127 9 4 16 18 16 33 0 37 26 71 55
71 29 0 55 -34 55 -72 0 -25 -9 -32 -84 -68 -105 -50 -126 -74 -126 -147 0
-44 5 -58 25 -78 33 -34 97 -34 147 0 l36 24 16 -24 c24 -37 75 -34 115 6 37
37 43 66 10 48 -36 -19 -43 2 -48 157 -6 144 -6 146 -34 170 -24 20 -39 24
-95 24 -42 0 -80 -7 -100 -17z m144 -209 c9 -37 -3 -97 -23 -112 -32 -23 -63
-25 -83 -7 -37 34 -22 92 33 125 44 27 65 25 73 -6z"/>
<path d="M3388 1038 c-50 -21 -62 -38 -29 -38 13 0 20 -10 25 -31 8 -40 8
-447 0 -476 -3 -12 -15 -25 -25 -28 -40 -13 -5 -25 69 -25 82 0 122 12 83 25
-26 8 -33 34 -29 110 l3 59 60 -3 c71 -3 103 8 140 45 90 90 88 284 -4 361
-33 28 -106 31 -150 6 -17 -10 -31 -21 -31 -25 0 -5 -4 -8 -10 -8 -5 0 -10 11
-10 25 0 32 -26 33 -92 3z m226 -84 c43 -44 58 -128 34 -205 -17 -59 -42 -81
-90 -77 -59 4 -78 43 -78 162 0 84 2 92 27 118 34 36 72 36 107 2z"/>
<path d="M4553 1041 c-69 -43 -73 -131 -6 -131 25 0 43 26 43 62 0 31 18 48
51 48 33 0 45 -14 54 -62 l7 -36 -84 -39 c-103 -49 -138 -86 -138 -148 0 -65
37 -105 96 -105 30 0 57 8 83 24 l38 25 13 -25 c21 -38 77 -34 118 9 40 41 40
51 3 44 -26 -5 -29 -3 -35 25 -3 17 -6 84 -6 149 0 110 -2 120 -24 146 -22 25
-31 28 -103 31 -64 2 -84 0 -110 -17z m145 -242 c-3 -54 -7 -65 -30 -82 -52
-39 -114 12 -86 71 15 31 72 72 100 72 17 0 19 -7 16 -61z"/>
<path d="M5203 1040 c-68 -41 -73 -130 -8 -130 28 0 45 18 45 48 0 39 18 62
49 62 35 0 50 -17 58 -63 l5 -35 -85 -41 c-104 -50 -137 -85 -137 -146 0 -32
7 -51 26 -74 22 -27 33 -31 73 -31 33 0 57 7 83 25 l36 24 16 -24 c24 -37 73
-34 114 6 39 38 40 51 5 47 -36 -4 -41 16 -42 170 -1 123 -1 124 -30 153 -27
27 -35 29 -103 29 -55 0 -81 -5 -105 -20z m151 -202 c13 -49 6 -80 -23 -109
-34 -34 -61 -37 -86 -9 -35 38 -20 85 40 122 40 24 61 23 69 -4z"/>

</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
